.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nav-link.active {
  font-weight: bold;
  color: #acf2bd !important;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  margin: auto;
}

input#notes {
  width: 100%;
}
.parallel-wrapper {
  display:flex;
}
.fieldset {
  display:flex;
  flex-direction: column;
}
input#Contact1, input#Contact2, input#Contact3 {
  width:300px;
  margin-right:15px;
}
.work-history {
  white-space: pre-wrap;
  width: 100%;
}
.location-search-input {
  width:100%;
}
.url-approval-table {
  white-space: pre-wrap;
}
.url-approval-table__url {
  display: inline-block;
  height: 24px;
  line-height: 24px;
}
.url-approval-table__url--removed {
  display: inline-block;
  height: 24px;
  text-decoration: line-through;
  background-color: #ffccc7;
  padding: 0 2px;
}
.url-approval-table__url--added {
  display: inline-block;
  height: 24px;
  background-color: #d9f7be;
  padding: 0 2px;
}
.MuiTypography-body1 {
  width:100%;
}
.MuiExpansionPanelSummary-root {
  background-color:#cfcfcf;
}
.MuiExpansionPanelDetails-root {
  background-color:#efefef;
}
.MuiTableCell-root.MuiTableCell-head {
  font-weight:bold;
  font-size:1rem;
}
.moved td {
  color:#fff;
}
.moved td a {
  color:#fff;
}

.MuiInputLabel-outlined {
  font-size:1.1rem !important;
  font-weight:bold !important;
}

.MuiAccordionSummary-content p.MuiTypography-body1 {
  font-size:1.1rem !important;
  font-weight:bold !important;
}

.container-fluid {
  --bs-gutter-x: 3.5rem;
}

/* Width and color of the scrollbar for WebKit, Firefox, and Edge (Chromium-based) */
::-webkit-scrollbar,
scrollbar-width {
  width: 16px;
  background-color: #f0f0f0; /* Optional: Background color of the scrollbar track */
}

/* Style of the scrollbar thumb for WebKit and Edge (Chromium-based) */
::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 8px; /* Optional: Customize the border radius of the thumb */
}

/* Style of the scrollbar thumb for Firefox */
scrollbar-color {
  scrollbar-color: #aaa #f0f0f0; /* Thumb color and track color */
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.resizable-column-header {
  position: relative;
  user-select: none;
}

.resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  background-color: transparent;
  transition: background-color 0.2s;
}

.resize-handle:hover {
  background-color: #ddd;
}